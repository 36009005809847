
<template>
  <div class="text-black-50 overflow-y-auto">
    <div
      class="
        px-5
        py-2
        flex
        justify-between
        bg-gray-200
        border-0 border-b border-gray-300
      "
    >
      <div class="flex">
        <div class="px-1" v-if="this.isStart == true">
          <i
            class="fas fa-play"
            @click="StartTimer"
            v-if="myAbilities.can('start_qa', 'pms')"
          ></i>
        </div>
        <div class="px-1" @click="PauseTimer" v-if="this.isPause == true">
          <span v-if="myAbilities.can('pause_qa', 'pms')">Pause</span>
        </div>
        <div class="px-1" v-if="this.isResume == true" @click="ResumeTimer">
          <span v-if="myAbilities.can('resume_qa', 'pms')">Resume</span>
        </div>
      </div>

      <div>
        <p v-if="this.currentQaData.start_time != null && this.currentQaData.end_time == null">
          Start time: {{ this.currentQaData.start_time | moment }}
        </p>
        <p v-else-if="this.currentQaData.end_time != null">End time: {{ this.currentQaData.end_time | moment }}</p>
      </div>
    </div>
    <div
      v-if="this.currentQaData.start_time != null && this.isComplete == false"
      class="px-5 py-2 bg-gray-200 border-0 border-b border-gray-300"
    >
      <div
        class="flex items-center mb-4"
        v-if="myAbilities.can('store_comment', 'pms')"
      >
        <input
          v-model="isPass"
          id="default-radio-1"
          type="radio"
          value=""
          name="default-radio"
          class="
            w-4
            h-4
            text-blue-600
            bg-gray-100
            border-gray-400
            focus:ring-blue-500
            dark:focus:ring-blue-600 dark:ring-offset-gray-800
            focus:ring-2
            dark:bg-gray-700 dark:border-gray-600
          "
          required
          @change="isPass = true"
          v-if="myAbilities.can('end_qa', 'pms')"
        />
        <label
          for="default-radio-1"
          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >Pass</label
        >
      </div>
      <div class="flex items-center">
        <input
          v-if="myAbilities.can('end_qa', 'pms')"
          v-model="isFail"
          id="default-radio-2"
          type="radio"
          value=""
          name="default-radio"
          class="
            w-4
            h-4
            text-blue-600
            bg-gray-100
            border-gray-400
            focus:ring-blue-500
            dark:focus:ring-blue-600 dark:ring-offset-gray-800
            focus:ring-2
            dark:bg-gray-700 dark:border-gray-600
          "
          @change="isFail = true"
        />
        <label
          for="default-radio-2"
          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >Fail</label
        >
      </div>
    </div>
    <div
      v-if="this.currentQaData.start_time != null && this.isComplete == false"
      class="px-5 py-2 bg-gray-200 border-0 border-b border-gray-300"
    >
      <div><h4 class="font-medium text-xl text-black-100 mb-4">Review</h4></div>
      <div class="w-full">
        <textarea class="w-full" v-model="comment"></textarea>
        <div class="flex justify-end">
          <button
            class="p-2 bg-blue-500 text-white-100 font-bold rounded-lg"
            @click="EndTask()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div
      id="accordion-collapse"
      data-accordion="collapse"
      class="text-black-50"
      v-if="this.currentTaskQa.length > 0"
    >
        <div v-if="this.currentTaskQa[0].comment != null">
      <div v-for="comment in this.currentTaskQa" :key="comment.index" >
        <div v-if="comment.comment != null">
          <h2 id="accordion-collapse-heading-1">
            <button
              type="button"
              class="
                flex
                justify-between
                items-center
                p-5
                w-full
                font-medium
                text-left text-black-50
                rounded-t-xl
                border border-b-0 border-gray-200
                focus:ring-4 focus:ring-gray-200
                dark:focus:ring-gray-800
                hover:bg-gray-100
                dark:hover:bg-gray-800
              "
              @click="toggleOpen(comment.id)"
              data-accordion-target="accordion-collapse-body-1"
              aria-expanded="false"
              aria-controls="accordion-collapse-body-1"
            >
              <div class="flex">
                <img
                  class="w-8 h-8 rounded-full mr-1"
                  src="../../../../assets/images/user-img.png"
                  alt="Rounded avatar"
                />
                <div class="w-full">
                  <div class="flex">
                    <div class="">
                      <p class="text-gray-700 font-medium">
                       
                        {{ comment.user.name }}
                        <span class="text-black-50 text-xs">{{
                          comment.created_at | moment
                        }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <span> {{ comment.is_pass ? "Pass" : "Fail" }} </span>
              <i
                 class="fas fa-angle-down text-black-300 text-sm pr-2"
                :class="`cmt-${comment.id}`"
                ></i>
              
            </button>
          </h2>
          <div
            :id="'accordion-body-' + comment.id"
            class="hidden"
            aria-labelledby="accordion-collapse-body-1"
          >
            <div
              class="
                p-5
                border border-b-2 border-gray-300
                dark:border-gray-700 dark:bg-gray-900
              "
            >
              <div class="flex">
                <div class="flex justify-between">
                  <p class="textblack-50" v-if="comment.comment != null">
                    {{ comment.comment }}
                  </p>
                  
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  
  </div>
</template>
<script>
import $ from "jquery";
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "QAstatus",
  data() {
    return {
      currentTaskQa: [],
      currentQaData: [],
      isStart: false,
      isPause: false,
      isResume: false,
      isComplete: false,
      isPass: false,
      isFail: false,
      comment: "",
      qaDataPage: {}
    };
  },
  filters: {
    moment(date) {
      return moment(date).format("MMMM DD, hh:mma");
    },
  },
  methods: {
    ...mapActions([
      "startQaTimer",
      "pauseQaTimer",
      "resumeQaTimer",
      "endQaTask",
    ]),
    ...mapMutations(["toggleLoading"]),

    // Accordion functions
    toggleOpen(id) {
      if ($(`#accordion-body-${id}`).hasClass("hidden")) {
        $(`#accordion-body-${id}`).removeClass("hidden");
        $(`.cmt-${id}`).removeClass('fa-angle-down')
        $(`.cmt-${id}`).addClass('fa-angle-up')
      } else {
        $(`#accordion-body-${id}`).addClass("hidden");
        $(`.cmt-${id}`).removeClass('fa-angle-up')
        $(`.cmt-${id}`).addClass('fa-angle-down')
      }
    },

    async StartTimer() {
      let res = await this.startQaTimer({ id: this.Task.id });
      this.isComplete= false;
      if (res.success) {
        this.$toasted.success(res.msg);
        this.currentQaData = res.data;
      } else {
        this.$toasted.error(res.msg);
      }
      this.btnShow();
    },
    btnShow() {
      if (Object.keys(this.currentQaData).length != 0) {
        if (this.currentQaData.end_time == null) {
          if (this.currentQaData.is_pause == 0) {
            //  show pause btn
            this.isPause = true;
            this.isResume = false;
            this.isStart = false;
          } else if(this.currentQaData.is_pause != 0) {
            // show resume btn
            this.isResume = true;
            this.isPause = false;
          }
          else{
          this.isStart = true;
          }
        }
      } 
      
      else {
        this.isStart = true;
      }
    },
    async PauseTimer() {
      let res = await this.pauseQaTimer({ id: this.currentQaData.id });
      if (res.success) {
      this.isComplete= false;
        this.$toasted.success(res.msg);
        this.currentQaData = res.data;
      } else {
        this.$toasted.error(res.msg);
      }
      this.btnShow();
    },
    async ResumeTimer() {
      let res = await this.resumeQaTimer({ id: this.currentQaData.id });
      if (res.success) {
      this.isComplete= false;
        this.$toasted.success(res.msg);
        this.currentQaData = res.data;
      } else {
        this.$toasted.error(res.msg);
      }
      this.btnShow();
    },
    // check validation for comment box
    validation() {
      if (this.isPass || this.isFail) {
        if (this.comment == "") {
          this.$toasted.error("please add review");
        } else {
          return true;
        }
      } else {
        this.$toasted.error("please check Pass or Fail");
        return false;
      }
    },
    async EndTask() {
      if(this.validation()){
      this.toggleLoading(true);
      this.isComplete = true;

let res = await this.endQaTask({
        id: this.currentQaData.id,
        is_pass: this.isPass,
        comment: this.comment,
      });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.comment = "";
        this.isPass = false;
        this.isFail = false;
        this.isStart= true;
        this.isPause = false;
        this.isResume = false;

      } else {
        this.$toasted.error(res.msg);
      }
      }
      
      this.btnShow();
    },
  },
  computed: {
    ...mapGetters(["Task", "TaskId", "TaskQa", "myAbilities"]),
  },
  async created() {
    this.currentTaskQa = this.Task.task_qa;
    this.currentTaskQa.forEach((element) => {
          if (element.end_time == null) {
            this.qaDataPage = element;
          }
        });
        this.currentQaData = this.qaDataPage;
    this.btnShow();
  },
};
</script>